//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';

export default class extends Controller
{
  static targets = [ 'tab', 'content' ];
  static classes = [ 'active' ];

  connect()
  {
    if( window.location.hash != "" )
    {
      this.tabHash = window.location.hash;
    }
    else
    {
      this.tabHash = this.tabTarget.hash;
    }

    this.update();
  }

  // Action: Tab selected
  select( e )
  {
    this.tabHash = e.currentTarget.hash;
    this.update();
  }

  // Update view
  update()
  {
    const activeClass = this.hasActiveClass ? this.activeClass : 'active';
    const tabHash = this.tabHash;
    const contentID = this.tab;

    this.tabTargets.forEach( tab =>
    {
      if( tab.hash == tabHash )
      {
        tab.classList.add( activeClass );
      }
      else
      {
        tab.classList.remove( activeClass );
      }
    });

    this.contentTargets.forEach( content =>
      {
        if( content.id == contentID )
        {
          content.classList.add( activeClass, 'show' );
        }
        else
        {
          content.classList.remove( activeClass, 'show' );
        }
      });
  }


  set tab( tab )
  {
    this._tab = tab;
  }

  get tab()
  {
    return this._tab;
  }

  set tabHash( tabHash )
  {
    this._tab = tabHash.substring( 1 );
  }

  get tabHash()
  {
    return `#${this._tab}`;
  }
}