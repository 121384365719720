//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';

export default class extends Controller
{
  static targets = [ 'show', 'edit', 'popover', 'focus' ];
  static values = { editable: Boolean };

  initialize()
  {
    this.clickEvent = this.clickEvent.bind( this );
    this.keyEvent = this.keyEvent.bind( this );
    this.editEvent = this.edit.bind( this );
  }


  connect()
  {
    // if( !this.isEditable ) return;

    // Add class for active IPE
    this.element.classList.add( 'ipe' );

    // Detect the form inside the controller
    this.form = this.element.querySelector( 'form' );

    // Detect input or select field
    if( this.hasFocusTarget )
    {
      this.focus = this.focusTarget;
    }
    else
    {
      this.focus = this.form.querySelector( 'input[type="text"],input[type="email"],textarea,select' );
    }

    this.isEditing = false;

    if( this.hasShowTarget && this.isEditable )
    {
      this.showTarget.addEventListener( 'click', this.editEvent );
      this.showTarget.classList.add( 'ipe-do-edit' );
    }
  }

  disconnect()
  {
    if( this.hasShowTarget )
    {
      this.showTarget.removeEventListener( 'click', this.editEvent );
    }
  }


  edit( e )
  {
    if( !this.isEditable || this.isEditing ) return;

    let target = e.target;
    while( target )
    {
      if( target.nodeName == 'A' ) return;
      if( target == this.showTarget ) break;
      target = target.parentElement;
    }

    e.stopPropagation();
    this.isEditing = true;
  }

  save( e )
  {
    if( !this.isEditable || !this.isEditing ) return;

    if( this.form )
    {
      this.form.classList.add( 'saving' );
      this.form.requestSubmit();
    }
    else
    {
      this.isEditing = false;
    }
  }

  cancel( e )
  {
    this.isEditing = false;
  }


  update()
  {
    if( this.isEditable && this.isEditing )
    {
      this.editState();
      document.addEventListener( 'click', this.clickEvent )
      document.addEventListener( 'keyup', this.keyEvent )
    }
    else
    {
      this.showState();
      document.removeEventListener( 'click', this.clickEvent )
      document.removeEventListener( 'keyup', this.keyEvent )
    }
  }

  editState()
  {
    this.element.classList.add( 'ipe-editing' );

    if( this.hasPopoverTarget )
    {
      this.popoverTarget.style.display = 'block';
    }
    else if( this.hasEditTarget )
    {
      if( this.hasShowTarget )
      {
        this.showTarget.style.display = 'none';
      }

      this.editTarget.style.display = 'block';
    }

    if( this.focus )
    {
      if( typeof this.focus.focus === "function" )
      {
        this.focus.focus();
      }

      if( typeof this.focus.select === "function" )
      {
        this.focus.select();
      }

      this.focus.dispatchEvent( new CustomEvent( 'ipeEdit' ));
    }
  }

  showState()
  {
    this.element.classList.remove( 'ipe-editing' );

    if( this.hasPopoverTarget )
    {
      this.popoverTarget.style.display = 'none';
    }
    else if( this.hasEditTarget )
    {
      this.editTarget.style.display = 'none';

      if( this.hasShowTarget )
      {
        this.showTarget.style.display = 'block';
      }
    }

    if( this.focus )
    {
      this.focus.dispatchEvent( new CustomEvent( 'ipeShow' ));
    }
  }


  clickEvent( e )
  {
    let target = e.target;

    while( target )
    {
      if( target == this.element )
      {
        return;
      }

      target = target.parentElement;
    }

    this.cancel( e );
  }

  keyEvent( e )
  {
    switch( e.key )
    {
      case 'Escape':
        this.cancel( e );
        break;
    }
  }

  refresh( e )
  {
    this.isEditing = false;

    if( this.hasShowTarget && this.isEditable )
    {
      this.showTarget.classList.add( 'ipe-do-edit' );
    }

    this.update();
  }


  get isEditable()
  {
    return !this.hasEditableValue || this.editableValue;
  }

  get isEditing()
  {
    return this._editing;
  }

  set isEditing( isEditing )
  {
    this._editing = isEditing;
    this.update();
  }
}